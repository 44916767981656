import { Component, HostListener } from '@angular/core';
import {ActivatedRoute} from '@angular/router'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  resizeTimeout: any
  width = window.innerWidth

  constructor(private route: ActivatedRoute) { }

  @HostListener('window:resize')
  onWindowResize() {
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout((() => { this.width = window.innerWidth }).bind(this), 100)
      
  }

  urlPathSegments(): string[] {
    ;(window as any).route = this.route
    return (this.route.snapshot as any)._routerState.url.slice(
      1).split('/') }

  id() {
    var r = this.urlPathSegments(), s = r.length ? r[0] : ''
    return s.replace(/\?.*$/, '') }

  capitalise(s: string) {
    return s.length ? s[0].toUpperCase() + s.slice(1) : '' }

  styl(s: string) {
    var id = this.id()
    return id == s || (!id && s == 'search') ? {
      'font-weight': 'var(--ropon-bold)',
      'text-decoration': 'underline'} : {} }

  logoPath() {
    return this.width > 991 ? '/assets/ropon-text.png' :
      '/assets/ropon.png' }
}
