<div class="container">
  <a class="navbar-brand" routerLink="/">
    <img src="{{logoPath()}}" />
  </a>
  <button class="navbar-toggler" type="button"
	  data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
	  aria-controls="navbarCollapse" aria-expanded="false"
	  aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
  <footer class="d-flex flex-row ms-auto gap-3">
    <a class="navbar-brand" target="_blank"
       href="https://arcticobserving.org">
      <img src="/assets/SAON_logo_2023.png" />
    </a>
    <a class="navbar-brand" target="_blank"
       href="https://arcticportal.org">
      <img src="/assets/ap-background.png" />
    </a>
    <a class="navbar-brand" targe="_blank"
       href="https://www.colorado.edu">
      <img src="/assets/logo_CU_Boulder.png" />
    </a>
  </footer>
    <ul class="navbar-nav justify-content-evenly">
      <li class="nav-item">
	<a [ngStyle]="styl('search')"
	    class="nav-link" routerLink="/">Home</a>
      </li>
      <li class="nav-item">
	<a [ngStyle]="styl('about')"
	   class="nav-link" routerLink="/about">About</a>
      </li>
      <!-- <li class="nav-item">
	<a [ngStyle]="styl('access')"
	   class="nav-link" routerLink="/access">Access</a>
      </li> -->
      <li class="nav-item">
	<a [ngStyle]="styl('contact')"
	   class="nav-link" routerLink="/contact">Contact</a>
      </li>
      <li class="nav-item">
	<a [ngStyle]="styl('poawg')"
	   class="nav-link" routerLink="/poawg">POAwg</a>
      </li>
      <li class="nav-item">
	<a [ngStyle]="styl('faq')"
	   class="nav-link" routerLink="/faq">FAQ</a>
      </li>
    </ul>
    <!-- <h1 [ngSwitch]="id()">
      <span *ngSwitchCase="'search'">
	<span>R</span>egistry <span>o</span>f <span>P</span>olar
	<span>O</span>bserving <span>N</span>etworks
      </span>
      <span *ngSwitchCase="'network'" style="color: white">
	Network Details
      </span>
      <span *ngSwitchDefault style="color: white">
	{{capitalise(id())}} RoPON
      </span>
    </h1> -->
  </div>
</div>
